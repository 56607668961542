<template>
  <div class="postMessage">
<!--    创建发布信息-->
    <div>
      <van-notice-bar
          left-icon="volume-o"
          text="用户须知：发布信息需真实有效！"
      />
      <van-field v-model="time" label="发布时间" disabled />

<!--      信息类型-->
      <van-field
          readonly
          clickable
          name="picker"
          :value="type"
          label="信息类型"
          placeholder="点击选择类型"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
<!--      信息简介-->
      <van-field
          v-model="introduction"
          rows="5"
          autosize
          label="信息简介"
          type="textarea"
          placeholder="请输入简介"

      />
<!--      地址-->

      <van-field
          readonly
          clickable
          name="area"
          :value="address"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"

      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
            :area-list="areaList"
            @confirm="onConfirm1"
            @cancel="showArea = false"
        />
      </van-popup>

<!--      联系电话-->
      <van-field v-model="phone" label="联系电话" placeholder="请输入联系电话"/>
<!--      其他联系方式-->
      <van-field v-model="other_contacts" label="附加信息" placeholder="请输入" />
      <van-cell center title="是否显示" v-show="this.data.length!=0">
        <template #right-icon>
          <van-switch v-model="checked" size="24" @change="changeState"/>
        </template>
      </van-cell>
      <van-button class="submitBtn" @click="subBtn"  v-show="this.data.length==0">发布信息</van-button>
      <van-button class="submitBtn" v-show="this.data.length!=0" @click="subBtn">修改信息</van-button>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
import {areaList} from "@vant/area-data";
import {Notify, Toast} from "vant";
export default {
  //import引入的组件需要注入到对象中才能使用",
  components: {},
  data() {
    //这里存放数据",
    return {
      id:'',
      data:[],
      type: '',//类型
      introduction:'',//简介
      address:'',//地址
      phone:'',//联系电话
      other_contacts:'',//其他联系方式
      time:'',
      columns: ['工程信息', '出售', '求购'],
      showPicker: false,
      showArea: false,
      areaList,
      checked: true,
      state:'',
      msgId:'',
      out:null
    };
  },
  //监听属性 类似于data概念",
  computed: {},
  //监控data中的数据变化",
  watch: {},
  //方法集合",
  methods: {
    async getMessage(){
      const {data}= await api.getUserPostMessage({id:this.id})
      this.data=data
      this.type=data[0].type
      this.introduction=data[0].introduction
      this.address=data[0].address
      this.phone=data[0].phone
      this.other_contacts=data[0].other_contacts
      this.time=data[0].release_time.slice(0,10)
      this.msgId=data[0].id
      if(data[0].status=='Y'){
        this.checked=true
        this.out=false
      }else {
        this.checked=false
        this.out=true
      }
    },
    onConfirm(value) {
      this.type = value;
      this.showPicker = false;
    },

    onConfirm1(values) {
      this.address = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      this.showArea = false;
    },
    // 提交/修改发布信息
    async subBtn(){
      if(this.type==''){
        return   Notify('请选择发布类型');
      }
      if(this.introduction==''){
        return   Notify('请填写信息简介');
      }
      if(this.address==''){
        return   Notify('请选择地址');
      }
      if(this.phone==''){
        return   Notify('请填写联系电话');
      }

      let params={
        userId:this.id,
        type:this.type,
        introduction:this.introduction,
        address:this.address,
        phone:this.phone,
        other_contacts:this.other_contacts
      }
      const {data,code}=await api.getInsertRelease(params)
      if(code==200){
       Toast.loading({
          message: '更新中...',
          forbidClick: true,
        });
        await   this.getMessage()
      }
    },
    async changeState(){
      if(this.checked==true){
        this.state="Y"
      }else {
        this.state="N"
      }
      let params={
        id:this.msgId,
        status:this.state
      }
      const {data,code}=await api.getReleaseStatus(params)
      if(code==200){
        Toast.loading({
          message: '更新中...',
          forbidClick: true,
        });
        await this.getMessage()
      }
    }
  },
  //生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  //生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    // window.localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJUZXN0VGV4dCI6WyLov5nph4zmmK_mtYvor5Xph4zpnaLliqDlhaXnmoTnibnmrormlbDmja7vvIEiLCLnjovkvbPmtYvor5XvvIzlnKhhdXRo5o6I5p2D5Lit5b-D6YeM6Z2i77yBIl0sImF1ZCI6WyJtYXBsYXlzLW1vYmlsZS1jbGllbnQiLCJtYXBsYXlzLWF1dGhjZW50ZXIiLCJtYXBsYXlzLXVzZXItc2VydmVyIiwibWFwbGF5cy1ndWFyYW50ZWUtc2VydmVyIl0sInVzZXJfbmFtZSI6IjE1MjgyMDQ3OTAyIiwic2NvcGUiOlsibGxsempkIiwidXNlciIsInZzZXJ2aWNlIiwiY29tbW9uIl0sImV4cCI6MTY2NDI2MDYzNSwiYXV0aG9yaXRpZXMiOlsiUk9MRV9jb21tb24iLCJjb21tb246dXNlciJdLCJqdGkiOiJhYjQ4ZWMyYy1kNjk5LTRhODctOGVjNS1kMzJhYzhlZGMxYTIiLCJjbGllbnRfaWQiOiJtYXBsYXlzIn0.aW8TrSUEGCA9RV8nxPtvctN1WGqUbp3swZ-639dM5tflEJ1JNQl5KJg41438MwBqtc15a3AZxOQVpdyxXr8aEbYmrN-CN98cAZ7dXLanDVEICXpVai_YZpXXjigLBopy39Q6kyM_b3a_nsg9VZb8fxLzPPUO2S-5OmpSampIFLUbdwKW2U7UWefwCv7p072dLCX0ucs2-5wdmBevI3JkJwl1R9bvE_vAbdLSXXysyw9Go7I-waWZ_Ka4Pczv_luKQ99k9zvYGqXHCvWbx9DItgvFOKc1reLOxdSHZxH4jGSrIGc0veMrV_rNPNQ0nsY16craBGqHyNIiU1jGI_U_4g')
    this.id=this.$route.params.id
  },
  //生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  //生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    this.getMessage()
  },

  //生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  //生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  //生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  //生命周期 - 销毁完成",
  //如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
}

</script>

<style scoped lang="less">
.postMessage{
  background: white;
  height: 100%;
  .submitBtn{
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 150px;
    width: 6rem;
    height: 1.2rem;
    background: rgb(25, 137, 250);
    text-align: center;
    color: white;
    line-height: 1.2rem;
    border-radius: 0.5rem;
    font-size: 0.5rem;
    border: none;
  }
}
</style>

